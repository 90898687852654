import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  SyncOutlined,
  FileExcelOutlined,
  CopyOutlined,
  EditOutlined,
  QuestionCircleOutlined
} from "@ant-design/icons";
import { 
  changeGameID,
  getUserCredential,
  reportGameID,
 } from "../../../config/networkConfig";
import { Table } from "ant-table-extensions";
import {
  PageHeader,
  Button,
  Breadcrumb,
  DatePicker,
  Select,
  Row,
  Modal,
  Input,
  Form,
  Typography
} from "antd";
import { isMobile } from "react-device-detect";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import { settings } from "../../../config/appConfig";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../../common/constants/sharedMsgBox";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function GameID() {
  const { Paragraph } = Typography;
  const { confirm } = Modal;
  const { Option } = Select;
  const { userId , role, username, compCode } = useSelector((state) => state.login);
  const [allReport, setAllReport] = useState("");

  const [todayDate, setTodayDate] = useState("");

  const [tableLoading ,    setTableLoading] = useState(false);
  const [btnLoading ,    setBtnLoading] = useState(false);
  
  const [date, setDate] = useState(moment(new Date()));
  
  const reportDate = useRef(null);
  const history = useHistory();

  const [oldGameID ,    setOldGameID] = useState('');
  const [platform ,    setPlatform] = useState('');

  
  useEffect(() => {
    const secret = window.location.href.split('?')[1]
    reportalReportList(secret);
    //reportShareDetailList();
    // eslint-disable-next-line
    console.log('date >>', new Date());
    console.log('date >>', Math.floor(Date.now()/1000));
  }, []);

  useEffect(()=> {
    setDate(date);
  },[date]);
  
  // functions
  const onReset = () => {
    setOldGameID('');
    setPlatform('');
  }
  
  const handleChangeGameID = (e) => {
    console.log('e >>', e);
    let requestData = {
      username           :     username,
      oldGameID          :     e.Username,
      provider           :     e.GameGroup === 'PUSSY' ? e.GameGroup : e.GameGroup.toLowerCase(),
    }

    confirm({
        className: "confirmationModalStyle",
        title: 'Confirmation',
        centered: true,
        icon: <QuestionCircleOutlined />,
        width: "500px",
        content: 
        <Paragraph>
            Are you sure you want to change the Game Provider and Game ID ? <br/>
            <ul>
              <li>Game Provider : <span style={{color:'red'}}>{e.GameGroup}</span></li>
              <li>Game ID : <span style={{color:'red'}}>{e.Username}</span></li>
            </ul>
        </Paragraph>,
        onOk() {
          updateUserGameID(requestData);
            // onReset();
        },
        onCancel() {
            onReset();
        }
    })
  }
  
  // API calls
  const reportalReportList = (secret) => {
    setTableLoading(true);
    var count = 1;

    let requestData = {
      secret : secret
    }

    reportGameID(requestData)
      .then((response) => response.json())
      .then((json) => {
        console.log("json -----", json);
        if (json.status === "ok") {
        const allReport = Object.keys(json.data).length
        ? json.data.map((data) => {
            setTableLoading(false);
            return {
              key                   : count++,
              GameGroup             : data.GameGroup,
              Prefix                : data.Prefix,
              Username              : data.Username,
              Status                : data.Status == 1 ? "Active" : "Inactive",
              ActiveTime            : data.ActiveTime == "1970-01-01 08:00:00" ? "-" : data.ActiveTime,
            };
            })
            : [];

            let todayDate = new Date().toLocaleDateString('en-GB').split('/').reverse().join('-');
            setTodayDate(todayDate);
            setAllReport(allReport);

          if (allReport.length == 0 ) {
            alertData.title = "Error";
            alertData.msg = "No data !";
            sharedErrorAlert(alertData);
            setTableLoading(false);
            return;
          }
        } 
        else {
          alertData.title = "Error";
          alertData.msg = json.msg;
          sharedErrorAlert(alertData);
          setTableLoading(false);
        }        
      });

      
  };

  const updateUserGameID = async (data) => {
    setBtnLoading(true);
    let requestData = {...data, timeNow : Math.floor(Date.now()/1000)};
    console.log('requestData >>', requestData);
    
    // call api to get user secret & userid
    let response1 = await getUserCredential(requestData);
    let result1 = await response1.json();
    if(result1.status === 'ok'){
      setTableLoading(true)
      if(Object.keys(result1.data).length > 0){
        requestData.secret = result1.data[0].secret;
        requestData.userId = result1.data[0].userId;
        requestData.forceChange = 'YES';
        
      console.log('requestData 2 >>', requestData);
        let response2 = await changeGameID(requestData);
        let result2 = await response2.json();

        if(result2.status === 'ok'){
          alertData.title = "Success";
          alertData.msg = result2.msg;
          sharedSuccessAlert(alertData);
          const secret = window.location.href.split('?')[1]
          reportalReportList(secret);
          setBtnLoading(false);
        }

    } else {
        alertData.title = "Failed";
        alertData.msg = result1.msg;
        sharedFailAlert(alertData);
        setBtnLoading(false);
      }
    }

    setTableLoading(false)
  }



  const columns = [
    {
      align: "center",
      title: "Game Provider",
      dataIndex: "GameGroup",
      width: 80,
      ellipsis: true,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return record
      },
    },
    {
      align: "center",
      title: "Game ID",
      dataIndex: "Username",
      width: 100,
      ellipsis: true,
      render:function(record, record2) {
        let finalUsername = record2.Prefix == null ? record : record2.Prefix+record
        return  (
                  <div className="center-container">
                    <Row
                      style={styles.rowStyle}
                    >
                      <div 
                        className="col1"
                        style={styles.gameIdStyle}
                      >{finalUsername}</div>
                      <div className="col2">
                        <Button type="primary" shape="circle"
                          onClick={() =>  navigator.clipboard.writeText(finalUsername)}
                          icon={<CopyOutlined />} 
                        >
                        </Button>
                      </div>
                    </Row>
                  </div>
                )
      },
    },
    {
      align: "center",
      title: "Status",
      dataIndex: "Status",
      width: 100,
      ellipsis: true,
      render:function(record) {
        return record
      },
    },
    {
      align: "center",
      title: "Game ID",
      dataIndex: "Username",
      width: 100,
      ellipsis: true,
      render:function(record, record2) {
        let finalUsername = record2.Prefix == null ? record : record2.Prefix+record
        return  (
                  <div className="center-container">
                    <Row
                      style={styles.rowStyle}
                    >
                      <div className="col2">
                        { record2.GameGroup === 'SBO' || record2.GameGroup === 'EVO' || record2.GameGroup === 'WSSPORT'  ?
                          // <span style={{color:'red'}}>N/A</span>
                          ''
                          :
                          <Button type="primary" style={styles.editBtnStyle}
                          onClick={() =>  handleChangeGameID(record2)}
                          icon={<SyncOutlined />} 
                        >
                          Change ID
                        </Button>
                        }
                      </div>
                    </Row>
                  </div>
                )
      },
    },
    {
      align: "center",
      title: "Active Time",
      dataIndex: "ActiveTime",
      width: 100,
      ellipsis: true,
      render:function(record) {
        return record
      },
    },
    // {
    //   align: "center",
    //   title: "Created By",
    //   dataIndex: "SysCreateBy",
    //   width: 100,
    //   ellipsis: true,
    //   render:function(record,record2) {
    //     if(record2.SysCreateBy){
    //       return record2.SysCreateBy
    //     } else {
    //       return 'SYSTEM'
    //     }
    //   },
    // },
    // {
    //   align: "center",
    //   title: "Update Time",
    //   dataIndex: "SysCreateTime",
    //   width: 100,
    //   ellipsis: true,
    //   render:function(record) {
    //     return record
    //   },
    // },
  ];

  const styles = {
    tableContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    inlineBlock: {
      display: "inline-block",
    },
    paginationStyle: {
      marginTop: 50,
    },
    pageHeaderContainer: {
      justifyContent: isMobile ? "" : "space-between",
      // display: "flex",
      flexDirection: isMobile ? "column" : "",
      alignItems: isMobile ? "center" : "",
      display: "inline",
    },
    resetBtn: {
      marginLeft: 10,
    },
    inline: {
      display: "inline",
    },
    hide: {
      marginLeft: 10000,
    },
    datePickerStyle: {
      width: 280,
    },
    datePickerSelectionStyle: {
      marginLeft: 10,
      width: 120,
    },
    refreshBtn: {
      marginLeft: 10,
    },
    headStyles: {
      backgroundColor: "#e2f2f0",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      fontSize: 20,
      height: 20,
    },
    bodyStyle: {
      backgroundColor: "#e2f2f0",
      paddingLeft: 30,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      justifyContent: "flex-start",
      display: "flex",
      height: 60,
    },
    tagStyle:{
      width:"100px",
    },
    memberBtn: {
      marginTop:"10px",
      width:"150px", 
      marginBottom:"8px"
    },
    rowStyle: {
      display: "flex",
      justifyContent: "center",
    },
    gameIdStyle: {
      width: 160,
      marginTop: 5,
      textAlign: "left",
    },
    editBtnStyle: {
      backgroundColor: 'orange',
      borderColor: 'orange',
      marginLeft: 10,
    }
  };

  return (
    <div>
      {        
        <div className="eachPageContent">
        {
          <div>
            <PageHeader
              ghost={false}
            >
              <div style={styles.pageHeaderContainer}>
                  <Button
                      onClick={() => history.goBack()}
                      loading={tableLoading}
                  >
                      Back
                  </Button>
              </div>
            </PageHeader>
            <div style={styles.tableContainer}>
              <Table
                loading={tableLoading} 
                columns={columns}
                dataSource={allReport}
                className="cs_gameId"
                scroll={{ x: 100, y: null }}
                pagination={{
                  defaultPageSize: 100,
                  showSizeChanger: true,
                  pageSizeOptions: settings.pageSizeOptionsSmall,
                  style: styles.paginationStyle,
                }}
              />
            </div>
          </div>
        }
        </div>      
      }
    </div>
      
  );
}