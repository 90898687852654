import React, { Component } from "react";
import "../App.less";
import { Layout, Menu, Button, Dropdown} from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  UserOutlined,
  LockOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { NavLink, Switch, Route , Link, Redirect, withRouter } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";

//Navigate
import Login from "../screens/login/login";
import Logout from "../screens/logout/logout";
import { externalFileNotFile } from "../screens/error/error";
import { route, mainMenu, logout, changePassword, generalPage, agent } from "../config/route";
import { copyright } from "../library/texts";
import { Logo } from "../common/components/Logo";
import { settings } from "../config/appConfig";
import { unlockMember } from "../library/permission"; //Permission
import style from "../resource/style/style";


const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: isMobile ? true : false,
      openKeys: [],
      title: "",
      path: window.location.pathname,
    };
  }
  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  state = {
    visible: false,
    openKeys: [],
  };

  styles ={
     hide: {
       display:"none"
     }
  }

  rootSubmenuKeys = ["sub1", "sub2", "sub3"];

  // location = ["/", ""];

  componentDidMount() {
    this.setTitle();
    this.setOpenKeys();
    this.preventIphoneZooming();
    this.updatePageId();
    this.setSideMenu();
  }

  componentDidUpdate(prevProps) {
    // Check if pageAccess prop has changed
    if (prevProps.pageAccess !== this.props.pageAccess) {
      this.setSideMenu();
    }

    if (prevProps.sideMenu !== this.props.sideMenu) {
      this.setPath();
    }

  }

  // setSideMenu = () => {
  //   const { pageAccess } = this.props;

  //   // Step 1: Group Pages by ParentId
  //   const groupedPages = pageAccess.reduce((acc, { ParentId, PageId, ParentName, ParentDisplayOrder, DisplayOrder, AccessStatus }) => {
  //     if (AccessStatus === 1) {  // Only include pages with AccessStatus of 1
  //       if (!acc[ParentId]) {
  //         acc[ParentId] = { parentName: ParentName, displayOrder: ParentDisplayOrder, items: [] };
  //       }
  //       acc[ParentId].items.push({ PageId, DisplayOrder });
  //     }
  //     return acc;
  //   }, {});

  //   // Step 2: Convert ParentId to Names and Sort Data
  //   const formattedMenu = Object.entries(groupedPages).map(([ParentId, { parentName, displayOrder, items }]) => {
  //     return {
  //       id: ParentId,
  //       displayOrder,
  //       parentName,
  //       items: items
  //         .sort((a, b) => a.DisplayOrder - b.DisplayOrder)  // Sort by DisplayOrder
  //         .map(item => item.PageId)                        // Map to PageId
  //     };
  //   }).sort((a, b) => a.displayOrder - b.displayOrder); // Sort by ParentDisplayOrder

  //   // Assuming there's a method to set the side menu in props
  //   if (Object.keys(formattedMenu).length > 0) {
  //     this.props.setSideMenu(formattedMenu);
  //   }
  // };

  setSideMenu = () => {
    const { pageAccess } = this.props;

    // Check if pageAccess is an empty array
    if (!Array.isArray(pageAccess) || pageAccess.length === 0) {
        // If pageAccess is empty, clear the side menu or handle it as needed
        this.props.setSideMenu([]);
        return; // Exit the function early
    }

    // Step 1: Group Pages by ParentId
    const groupedPages = pageAccess.reduce((acc, { ParentId, PageId, ParentName, ParentDisplayOrder, DisplayOrder, AccessStatus }) => {
        if (AccessStatus === 1) {  // Only include pages with AccessStatus of 1
            if (!acc[ParentId]) {
                acc[ParentId] = { parentName: ParentName, displayOrder: ParentDisplayOrder, items: [] };
            }
            acc[ParentId].items.push({ PageId, DisplayOrder });
        }
        return acc;
    }, {});

    // Step 2: Convert ParentId to Names and Sort Data
    const formattedMenu = Object.entries(groupedPages).map(([ParentId, { parentName, displayOrder, items }]) => {
        return {
            id: ParentId,
            displayOrder,
            parentName,
            items: items
                .sort((a, b) => a.DisplayOrder - b.DisplayOrder)  // Sort by DisplayOrder
                .map(item => item.PageId)                        // Map to PageId
        };
    }).sort((a, b) => a.displayOrder - b.displayOrder); // Sort by ParentDisplayOrder

    // Assuming there's a method to set the side menu in props
    this.props.setSideMenu(formattedMenu);
  };


  setPath = () => {
    const { signIn, sideMenu } = this.props;
    const location = window.location;
  
    // Check if the pathname is "/" and signIn is true
    if (location.pathname === "/" && signIn) {
      let pathIndex = "";
  
      // Iterate over the sideMenu array
      for (const item of sideMenu) {
        // Check if 'items' array is non-empty
        if (Array.isArray(item.items) && item.items.length > 0) {
          pathIndex = item.items[0]; // Take the first element of the array
          break; // Exit the loop after finding the first valid path
        }
      }
  
      // If we found a valid pathIndex, use it to set the new path
      if (pathIndex) {
        // Assuming generalPage[pathIndex] exists and has a path property
        const newPath = generalPage[pathIndex]?.path || "/";
        // Update the location pathname
        location.href = newPath;
      }
    }
  
    this.updatePageId();
  }
  
  setTitle() {
    generalPage.forEach((page) => {
      if (page.path === window.location.pathname) {
        this.setState({ title: page.title });
      }
    });
  }

  setOpenKeys = () => {
    const { sideMenu } = this.props;
  
    for (let item of sideMenu) {
      // Check if 'items' array is non-empty
      if (item.items.length > 0) {
        // Check if any item path matches the current URL path
        if (item.items.some(index => generalPage[index]?.path === window.location.pathname)) {
          
          // Handle special cases for 'reporting' related keys
          // ['reportOperation', 'reportAccount', 'reportMisc', 'reportCRM', 'reportOther']
          if (['5', '6', '7', '8', '9'].includes(item.id)) {
            this.setState({ openKeys: [item.id, 'reporting'] });
          } else {
            this.setState({ openKeys: [item.id] });
          }
          break;  // Exit loop after finding the first matching key
        }
      }
    }
  }
  
  updatePageId = () => {
    const { pageAccess } = this.props;
    // const { location, dispatch } = this.props;
    const location = window.location
    const currentPath = location.pathname;

    // Find the matching page configuration
    const matchedPage = generalPage.find(page => page.path === currentPath);

    if (matchedPage) {
      // Dispatch the action to update pageId
      // this.props.setPageId(matchedPage.key)
      const pageId = matchedPage.key;
      const currentPageAccessData = pageAccess.find(access => access.PageId === pageId);

      if (currentPageAccessData) {
        // Map the pageAccessData to extract only the required fields
        const mappedCurrentPageAccessData = {
          pageId: currentPageAccessData.PageId,
          read: currentPageAccessData.ReadAccess,
          write: currentPageAccessData.WriteAccess,
          create: currentPageAccessData.CreateAccess,
          delete: currentPageAccessData.DeleteAccess
        };
        this.props.setCurrentPageAccess(mappedCurrentPageAccessData)
      }
   };
  }
  
  setReportingSubMenu() {
    // const { pageAccess } = this.props;
    const { sideMenu } = this.state;
    const reportingSubmenus = {
      // ReportOperation: [25, 34, 37, 38, 41, 42, 40, 35, 43, 45, 47],
      // ReportingCRM: [21, 30, 16],
      // ReportMisc: [27, 10, 26, 15],
      // ReportAccount: [17, 54, 56],
      reportOperation: sideMenu.reportOperation,
      reportCRM: sideMenu.reportCRM,
      reportMisc: sideMenu.reportMisc,
      reportAccount: sideMenu.reportAccount,
      reportOther: sideMenu.reportOther,
    };

  
    const pathname = window.location.pathname;
    const openKeys = ["reporting"];
  
    for (let key in reportingSubmenus) {
      if (reportingSubmenus[key].some((index) => generalPage[index].path === pathname)) {
        openKeys.push(key);
      }
    }
  
    this.setState({ openKeys });
  }
  
  preventIphoneZooming() {
    document.addEventListener("gesturestart", (e) => {
      e.preventDefault(); // disable iPhone zooming
    });
  }

  toggle = () => {
    if (isMobile) {
      document.getElementById("menuBackground").style.display = "flex";
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    }
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  menuBgOnclick = () => {
    if (isMobile) {
      this.setState({
        collapsed: !this.state.collapsed,
      });
      document.getElementById("menuBackground").style.display = "none";
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
  };

  subMenuClick = () => {
    var that = this;
    generalPage.forEach(function (cs) {
      if (cs.path === window.location.pathname) {
        that.setState({ title: cs.title });
      }
    });
  };

  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(
      (key) => this.state.openKeys.indexOf(key) === -1
    );
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  menu = () => {
    return (
      <Menu>
        {(
          <Menu
            style={{
              paddingTop: 4,
              marginTop: -4,
              marginBottom: -4,
              paddingBottom: 2,
            }}
          >
            <Menu.Item 
              onClick={() => {
                window.location.reload();
              }}
              key={changePassword[0].path}
              style={{
                paddingTop: -2,
                marginTop: -5,
                marginBottom: -4,
              }}
              
            >
              <NavLink to={changePassword[0].path}>
                <div 
                  className="menu-item"
                  style={{
                    justifyContent: "flex-start",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <LockOutlined className="menu-icon" />
                  <span className="menu-text">Change Password</span>
                </div>
              </NavLink>
            </Menu.Item>

            <Menu.Item key={logout[0].path} className="logoutBorder"
              style={{
                marginBottom: -2,
              }}
            >
              <NavLink to={logout[0].path}>
                <div 
                  className="menu-item"
                  style={{
                    justifyContent: "flex-start",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <LogoutOutlined className="menu-icon" />
                  <span className="menu-text">Logout</span>
                </div>
              </NavLink>
            </Menu.Item>
          </Menu>
        )}
      </Menu>
    );
  };

  renderMenuItems = (keys, individual) => {
    const { compCode } = this.props;
    const styles = {
      menuFontSize: {
        fontSize:
          window.innerWidth === 280 || window.innerWidth === 320 ? null : 20,
      },
    };

    if (individual) {
      return keys.map((key) => {
        // Special condition for key 38
        // if (key === 38 && customerService[38].code.includes(compCode)) {
        //   return null;
        // }
  
        return (
          <Menu.Item
            key={generalPage[key].path}
            style={styles.menuFontSize}
            icon={generalPage[key].icon}
            onClick={() => window.location.reload()}
          >
            <NavLink to={generalPage[key].path}>
              {generalPage[key].menutitle}
            </NavLink>
          </Menu.Item>
        );
      });
    }

    return keys.map((key) => {
      // Special condition for key 38
      // if (key === 38 && customerService[38].code.includes(compCode)) {
      //   return null;
      // }

      return (
        <Menu.Item
          key={generalPage[key].path}
          style={style.menuFontSize}
          onClick={() => window.location.reload()}
        >
          <NavLink to={generalPage[key].path}>
            {generalPage[key].menutitle}
          </NavLink>
        </Menu.Item>
      );
    });
  };

  // renderMenuItems = (items) => {
  //   const { compCode } = this.props;
  //   const styles = {
  //     menuFontSize: {
  //       fontSize:
  //         window.innerWidth === 280 || window.innerWidth === 320 ? null : 20,
  //     },
  //   };
  
  //   return items.map((pageId) => {
  //     // Special condition for key 38
  //     // if (pageId === 38 && customerService[38].code.includes(compCode)) {
  //     //   return null;
  //     // }
  
  //     return (
  //       <Menu.Item
  //         key={generalPage[pageId].path}
  //         style={styles.menuFontSize}
  //         icon={generalPage[pageId].icon}
  //         onClick={() => window.location.reload()}
  //       >
  //         <NavLink to={generalPage[pageId].path}>
  //           {generalPage[pageId].menutitle}
  //         </NavLink>
  //       </Menu.Item>
  //     );
  //   });
  // };
  
  

  render() {
    const { signIn, role, username, sideMenu } = this.props;
    const menuData = {}

    if (signIn === false) {
      return (
        <Switch>
          <Route path={[route.Default, route.Login, route.All]} exact component={Login} />
          <Route path={route.All} component={externalFileNotFile} />
        </Switch>
      );
    }

    return (
      <Layout>
        <div
          id="menuBackground"
          className="menuBackground"
          onClick={this.menuBgOnclick}
        />
        <Sider
          trigger={null}
          collapsed={this.state.collapsed}
          collapsedWidth={isMobile ? 0 : 80}
          width={"280px"}
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
          }}
        >
          <div
            style={{
              height: 64,
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <div>
              {!this.state.collapsed ? (
                <Logo logoType="customerservice" />
              ) : null}
            </div>
          </div>
         
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[window.location.pathname]}
          openKeys={this.state.openKeys}
          onOpenChange={this.onOpenChange}
        >
          {(() => {
            let hasRenderedReporting = false; // Flag to track if "Reporting" menu has been rendered
          
            return Object.entries(sideMenu).map(([key, menu]) => {
              // Convert menu.id to number for comparison
              const menuKey = Number(menu.id);
              const icon = mainMenu.find(item => item.key === menuKey)?.icon;
              // Check if the current id is part of the reporting submenus
              if (["5", "6", "7", "8", "9"].includes(menu.id)) { 
                
                // If "Reporting" menu has already been rendered, skip the rest
                if (hasRenderedReporting) {
                  return null;
                }

                // Find and filter all the relevant reporting submenus
                const reportingItems = ["5", "6", "7", "8", "9"]
                  .map(reportingId => sideMenu.find(menu => menu.id === reportingId))
                  .filter(Boolean) // Filter out any undefined entries
                  .sort((a, b) => a.displayOrder - b.displayOrder);

                // Render the "Reporting" menu and set the flag to true
                if (reportingItems.length > 0) {
                  hasRenderedReporting = true;
                  return (
                    <SubMenu
                      key="reporting"
                      style={{ fontSize: 18 }}
                      title="Reporting"
                      collapsed={false}
                      icon={mainMenu[3].icon}
                    >
                      {reportingItems.map(({ id: reportId, parentName: reportParentName, items: reportItems }) => (
                        <SubMenu
                          key={reportId}
                          style={{ fontSize: 16 }}
                          // title={reportId.replace('report', '').replace(/([A-Z])/g, ' $1').trim()}
                          title={reportParentName}
                          collapsed={false}
                        >
                          {this.renderMenuItems(reportItems)}
                        </SubMenu>
                      ))}
                    </SubMenu>
                  );
                }
              }

              // Render other menu items as usual
              if (menu && menu.id != 11) {
                return (
                  <SubMenu
                    key={menu.id}
                    style={{ fontSize: 18 }}
                    title={menu.parentName}
                    collapsed={false}
                    icon={icon}
                  >
                    {this.renderMenuItems(menu.items)}
                  </SubMenu>
                );
              } else if ( menu.id == 11) {
                return (
                  this.renderMenuItems(menu.items, "others")
                )
              }

              return null;
            });
          })()}
        </Menu>

        </Sider>
        <Layout className="site-layout">
          {isMobile ? (
            <Header
              className="site-layout-background"
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                height: 68,
              }}
            >
              {React.createElement(
                this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: this.toggle,
                }
              )}
              <div
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {this.state.title}
              </div>
              <div>
                <div
                  style={{
                    fontSize: 18,
                  }}
                >
                  <Dropdown overlay={this.menu} placement="bottomLeft">
                    <Button
                      style={{
                        border: "none",
                        width: 80,
                        height: 65,
                      }}
                      icon={<UserOutlined />}
                      size="large"
                    ></Button>
                  </Dropdown>
                </div>
              </div>
            </Header>
          ) : (
            <Header
              className="site-layout-background"
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                marginLeft: this.state.collapsed ? 64 : 280,
                height: 68,
                alignItems: "center",
              }}
            >
              {React.createElement(
                this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: this.toggle,
                }
              )}
              <div
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {this.state.title}
              </div>
              <div>
                <div
                  style={{
                    fontSize: 18,
                    marginRight: 20,
                  }}
                >
                  <Dropdown overlay={this.menu} placement="bottomLeft">
                    <Button
                      style={{
                        border: "none",
                        width: 200,
                        height: 65,
                      }}
                      icon={<UserOutlined />}
                      size="large"
                    >
                      {role}_{username}
                    </Button>
                  </Dropdown>
                </div>
              </div>
            </Header>
          )}
          <Content
            className="site-layout-background"
            style={{
              marginTop: "5px ",
              marginLeft: isMobile ? "" : this.state.collapsed ? 64 : 280,
              backgroundColor: "#f0f2f5",
            }}
          >
            <div
              style={{
                minHeight: 280,
              }}
            >
              <Switch>
                <Route exact path={logout[0].path} component={Logout} />
                {generalPage.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                  />
                ))}
              </Switch>
            </div>
          </Content>
          {isMobile ? (
            <Footer style={{ textAlign: "center" }}>
              <div>{copyright.fullText()}</div>
              <div>V: {settings.version}</div>
            </Footer>
          ) : (
            <Footer style={{ textAlign: "center", marginLeft: 280 }}>
              <div>{copyright.fullText()}</div>
              <div>V: {settings.version}</div>
            </Footer>
          )}
        </Layout>
      </Layout>
    );
  }
}

// const mapStateToProps = ({ login }) => ({
//   signIn: login.signIn,
//   username: login.username,
//   role: login.role,
//   compCode: login.compCode,
// });

// const mapStateToProps = (state) => ({
//   signIn: state.login.signIn,
//   username: state.login.username,
//   role: state.login.role,
//   compCode: state.login.compCode,
//   pageAccess: state.access,
//   currentPageId : state.currentPageId,
// })

const mapStateToProps = (state) => {
  console.log("Redux State:", state);
  return {
    signIn: state.login.signIn,
    username: state.login.username,
    role: state.login.role,
    compCode: state.login.compCode,
    pageAccess: state.pageAccess,
    currentPageAccess : state.currentPageAccess,
    sideMenu: state.sideMenu,
    };
};

const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch({ type: "sign_out" }),
  setCurrentPageAccess: (payload) => dispatch({ type: "set_current_page_access", payload: payload}),
  setSideMenu: (payload) => dispatch({ type: "set_side_menu", payload: payload}),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
