import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  SearchOutlined,
  FileExcelOutlined,
  DollarCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { 
  reportDailyTrxnStats
 } from "../../../config/networkConfig";
import { Table } from "ant-table-extensions";
import {
  PageHeader,
  Button,
  Breadcrumb,
  Statistic,
  Row,
  Card,
  DatePicker,
  Space,
  Select,
  Checkbox,
  Divider,
  Switch,
} from "antd";
import { isMobile } from "react-device-detect";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import { settings } from "../../../config/appConfig";
import { FormatDataMobile } from "../../../library/numbers";
import moment from "moment";
import { DailyTrxnStatsVw } from "../../../library/permission";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../../common/constants/sharedMsgBox";


export default function ReportDailyTrxnStats() {

  const { Option } = Select;
  const CheckboxGroup = Checkbox.Group;
  const plainOptions = ['Deposit', 'Withdrawal', 'Comm2Bank', 'To Resolve'];
  const defaultCheckedList = ['Deposit', 'Withdrawal', 'Comm2Bank', 'To Resolve'];
  const [checkAll, setCheckAll] = useState(true);

  const [depositCheckbox, setDepositCheckbox] = useState(true);
  // const [comm2WalletCheckbox, setComm2WalletCheckbox] = useState(true);
  const [withdrawalCheckbox, setWithdrawalCheckbox] = useState(true);
  const [comm2BankCheckbox, setComm2BankCheckbox] = useState(true);
  const [toResolveCheckbox, setToResolveCheckbox] = useState(true);

  const [depositChange, setDepositChange] = useState(0);
  // const [comm2WalletChange, setComm2WalletChange] = useState(0);
  const [withdrawalChange, setWithdrawalChange] = useState(0);
  const [comm2BankChange, setComm2BankChange] = useState(0);
  const [toResolveChange, setToResolveChange] = useState(0);

  const { userId , role, username, compCode } = useSelector((state) => state.login);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [report, setReport] = useState("");
  const [allReport, setAllReport] = useState("");
  const [depositAmtTotal, setDepositAmtTotal] = useState("");
  const [withAmountTotal, setWithAmountTotal] = useState("");
  const [confirmWithdrawAmtTotal, setConfirmWithdrawAmtTotal] = useState("");
  const [toResolveAmtTotal, setToResolveAmtTotal] = useState("");
  const [commToBankAmtTotal, setCommToBankAmtTotal] = useState("");
  const [commToWalletAmtTotal, setCommToWalletAmtTotal] = useState("");

  const [todayDate, setTodayDate] = useState("");

  const [tableLoading ,          setTableLoading] = useState(false);
  const [trxLoading, setTrxLoading] = useState(false);

  const [type, setType] = useState("thismonth");
  const [trxDateFrom, setTrxDateFrom] = useState("");
  const [trxDateTo, setTrxDateTo] = useState("");

  // const [expandButton, setExpandButton] = useState(false);
  const [expand, setExpand]  = useState(false);
  const [promotionWidth, setPromotionWidth] = useState(0);

  const reportDateFrom = useRef(null);
  const reportDateTo = useRef(null);

  useEffect(() => {
    // if (DailyTrxnStatsVw(role, username)){
    // reportallReportList();
    // }
    //reportShareDetailList();
    // eslint-disable-next-line
  }, []);

  useEffect(()=> {
    setTrxDateFrom(trxDateFrom);
    setTrxDateTo(trxDateTo);
  },[trxDateFrom,trxDateTo]);

  // window.setTimeout( function() {
  //   window.location.reload();
  // }, 600000);

  const onClickExportBtn = ()=> {

    if (allReport.length === 0 ) {
      alertData.title = "Error";
      alertData.msg = "No data to download !";
      sharedErrorAlert(alertData);
      return;
    }
    // onClickExportBtn();
    document.getElementById("export-button-1").click();
  }

  const resetBtn = () => {
    setAllReport([]);
    setDepositAmtTotal(0);
    setWithAmountTotal(0);
    setConfirmWithdrawAmtTotal(0);
    setToResolveAmtTotal(0);
    setCommToBankAmtTotal(0);
    setType("thismonth");
    setDepositCheckbox(true);
    // setComm2WalletCheckbox(true);
    setWithdrawalCheckbox(true);
    setComm2BankCheckbox(true);
    setToResolveCheckbox(true);
    setCheckedList(defaultCheckedList);
    setDepositChange(0);
    // setComm2WalletChange(0);
    setWithdrawalChange(0);
    setComm2BankChange(0);
    setToResolveChange(0);
  };

  const trxDatePicker = (date, dateString) => {
    setType("custom");
    setTrxDateFrom(date);
  };

  const trxDatePicker2 = (date, dateString) => {
    setType("custom");
    setTrxDateTo(date);
  };

  const PickerWithType = ({ type, onChange }) => {
    if (type === 'custom'){
        setTrxDateFrom(trxDateFrom);
        setTrxDateTo(trxDateTo);
    }
    if (type === 'today'){
      let today = new Date().toLocaleDateString("en-CA", {day: "numeric"});
      setTrxDateFrom(moment().set('date', today))
      setTrxDateTo(moment().set('date', today))
    } 
    if (type === 'yesterday'){
      let yesterday = new Date(new Date().getTime());
      yesterday.setDate(yesterday.getDate()-1);
      let yestDay = yesterday.toLocaleDateString("en-CA", {day: "numeric"});
      let yestMonth = yesterday.toLocaleDateString("en-CA", {month: "numeric"});
      let yestYear = yesterday.toLocaleDateString("en-CA", {year: "numeric"});
      yestMonth = yestMonth - 1

      setTrxDateFrom(moment().set({'year': yestYear, 'month': yestMonth, 'date': yestDay}))
      setTrxDateTo(moment().set({'year': yestYear, 'month': yestMonth, 'date': yestDay}))
    }
    if (type === 'thisweek'){
      let today = new Date();
      let firstDay = new Date(today.setDate(today.getDate() - today.getDay() + 1));
      let firstDayOfWeek = firstDay.toLocaleDateString("en-CA", {day: "numeric"});
      let firstMonthOfWeek = firstDay.toLocaleDateString("en-CA", {month: "numeric"});
      let firstYearOfWeek = firstDay.toLocaleDateString("en-CA", {year: "numeric"});
          firstMonthOfWeek = firstMonthOfWeek - 1; 
      let lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 7));
      let lastDayOfWeek = lastDay.toLocaleDateString("en-CA", {day: "numeric"});
      let lastMonthOfWeek = lastDay.toLocaleDateString("en-CA", {month: "numeric"});
      let lastYearOfWeek = lastDay.toLocaleDateString("en-CA", {year: "numeric"});
          lastMonthOfWeek = lastMonthOfWeek - 1;
      setTrxDateFrom(moment().set({'year': firstYearOfWeek, 'month': firstMonthOfWeek, 'date': firstDayOfWeek}));
      setTrxDateTo(moment().set({'year': lastYearOfWeek, 'month': lastMonthOfWeek, 'date': lastDayOfWeek}));
    }
    if (type === 'thismonth'){
      let date = new Date(), y = date.getFullYear(), m = date.getMonth();
      let firstDay = new Date(y, m, 1);
      let lastDay = new Date(y, m + 1, 0);

      let month = firstDay.toLocaleDateString("en-CA", {month: "numeric"})

      let year = lastDay.toLocaleDateString("en-CA", {year: "numeric"})
      lastDay = lastDay.toLocaleDateString("en-CA", {day: "numeric"})
      month = month - 1

      setTrxDateFrom(moment().set({'year': year, 'month': month, 'date': 1}))
      setTrxDateTo(moment().set({'year': year, 'month': month, 'date': lastDay}))
    }
    if (type === 'lastmonth'){
      let date = new Date(), y = date.getFullYear(), m = date.getMonth();
      let firstDay = new Date(y, m-1, 1);
      let lastDay = new Date(y, m, 0);

      let month = firstDay.toLocaleDateString("en-CA", {month: "numeric"})
      let yearOfFirstDay = firstDay.toLocaleDateString("en-CA", {year: "numeric"})

      let yearOfLastDay = lastDay.toLocaleDateString("en-CA", {year: "numeric"})
      lastDay = lastDay.toLocaleDateString("en-CA", {day: "numeric"})
      month = month - 1

      setTrxDateFrom(moment().set({'year': yearOfFirstDay, 'month': month, 'date': 1}))
      setTrxDateTo(moment().set({'year': yearOfLastDay, 'month': month, 'date': lastDay}))
    }
    return <DatePicker picker={type} onChange={onChange} />;
  };

  const searchBar = () => {
    if (!trxDateFrom) {
      reportDateFrom.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please select Date From !";
      sharedErrorAlert(alertData);
      return;
    }

    if (!trxDateTo) {
      reportDateTo.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please select Date To !";
      sharedErrorAlert(alertData);
      return;
    }

    if (trxDateTo < trxDateFrom || trxDateFrom > trxDateTo) {
      alertData.title = "Error";
      alertData.msg = "Please select a valid Date Range !";
      sharedErrorAlert(alertData);
      return;
    }


    setTrxLoading(true);
    reportallReportList();
  }

  // const detailsBtn = (record) => {

  //   if(expandButton == false){
  //     setExpandButton(true);
  //     setPromotionWidth(150);

  //   }else if (expandButton == true){
  //     setExpandButton(false);
  //     setPromotionWidth(0);

  //   }
  // }
  const expandButton = (record) => {

    if(expand == false){
      setExpand(true);

    }else if (expand == true){
      setExpand(false);
    }
  }

  const onChange = (list) => {
    setCheckedList(list);
    setCheckAll(list.length === plainOptions.length);
    if(list.includes('Deposit')){
      setDepositCheckbox(true);
      setDepositChange(depositAmtTotal)

    }else if(!list.includes('Deposit')){
      setDepositCheckbox(false);
      setDepositChange(0)
    }
    
    // if(list.includes('Comm2Wallet')){
    //   setComm2WalletCheckbox(true);
    //   setComm2WalletChange(commToWalletAmtTotal);
    // }else if(!list.includes('Comm2Wallet')){
    //   setComm2WalletCheckbox(false);
    //   setComm2WalletChange(0);
    // }

    if(list.includes('Withdrawal')){
      setWithdrawalCheckbox(true);
      setWithdrawalChange(withAmountTotal);
    }else if(!list.includes('Withdrawal')){
      setWithdrawalCheckbox(false);
      setWithdrawalChange(0);
    }

    if(list.includes('Comm2Bank')){
      setComm2BankCheckbox(true);
      setComm2BankChange(commToBankAmtTotal);
    }else if(!list.includes('Comm2Bank')){
      setComm2BankCheckbox(false);
      setComm2BankChange(0);
    }

    if(list.includes('To Resolve')){
      setToResolveCheckbox(true);
      setToResolveChange(toResolveAmtTotal);
    }else if(!list.includes('To Resolve')){
      setToResolveCheckbox(false);
      setToResolveChange(0);
    }
  };

  const reportallReportList = () => {
    setTableLoading(true);
    var count = 0;

    let requestData = {
      datefrom : moment(trxDateFrom).format("YYYY-MM-DD"),
      dateto: moment(trxDateTo).format("YYYY-MM-DD"),
    }

    reportDailyTrxnStats(requestData)
      .then((response) => response.json())
      .then((json) => {
        console.log("json -----", json);
        if (json.status === "ok") {
        const allReport = Object.keys(json.data.firstRowData).length
        ? json.data.firstRowData.map((data) => {
            setTableLoading(false);
            return {
              
              trxnDate              : data.TrxnDate,
              startTime             : data.StartTime,
              endTime               : data.EndTime,
              totalUser             : data.TotalUser,
              registerUser          : data.RegisterUser,
              directRegisterUser    : data.RegisterUser - data.InviteUser,
              inviteUser            : data.InviteUser,
              activeUser            : data.ActiveUser,
              depositUser           : data.DepositUser,
              depositUserUnique     : data.DepositUserUnique,
              depCount              : data.DepCount,
              uniqueDep             : data.UniqueDep,
              uniqueDepSummary      : data.UniqueDepSummary,
              depositAmt            : data.DepositAmt,
              maxDepositAmt         : data.MaxDepositAmt,
              withdrawCount         : data.WithdrawCount,
              uniqueWth             : data.UniqueWth,
              withdrawAmt           : data.WithdrawAmt,
              maxWthAmt             : data.MaxWthAmt,
              commToWallet          : data.CommToWallet,
              commToBank            : data.CommToBank,
              depositFirstCount     : data.DepositFirstCount,
              depositFirstAmount    : data.DepositFirstAmount,
              depositFirstTimeAmt   : data.DepositFirstTimeAmt,
              depositRegularCount   : data.DepositRegularCount,
              depositRegularCountSummary  : data.DepositRegularCountSummary,
              depositRegularAmount  : data.DepositRegularAmount,
              remark                : data.Remark,
              adjustAmt             : data.AdjustAmt,
              toResolve             : data.ToResolve,
              playerTurnover        : data.PlayerTurnover,
              playerWinLoss         : data.PlayerWinLoss,
              promotionCost         : (data.PromotionCost)+parseFloat(FormatDataMobile(data.MissionAmt))+parseFloat(FormatDataMobile(data.DailyFreeCredit))+parseFloat(FormatDataMobile(data.PushNotification))+parseFloat(FormatDataMobile(data.LearnMore))+parseFloat(FormatDataMobile(data.BindBankAcc))+parseFloat(FormatDataMobile(data.PlayerRebate))+parseFloat(FormatDataMobile(data.DownlineComm)),
              fortuneWheelAmt       : FormatDataMobile(data.FortuneWheelAmt),
              inviteAngpaoAmt       : FormatDataMobile(data.InviteAngpaoAmt),
              permanentAngpaoAmt    : FormatDataMobile(data.PermanentAngpaoAmt),
              missionAmt            : FormatDataMobile(data.MissionAmt),
              playerRebate          : FormatDataMobile(data.PlayerRebate),
              downlineComm          : FormatDataMobile(data.DownlineComm),
              dailyFreeCredit       : FormatDataMobile(data.DailyFreeCredit),
              pushNotification      : FormatDataMobile(data.PushNotification),
              learnMore             : FormatDataMobile(data.LearnMore),
              bindBankAcc           : FormatDataMobile(data.BindBankAcc),
              winLoss               : data.WinLoss,
              playerWithBank        : data.PlayerWithBank,
              playerWithoutBank     : data.PlayerWithoutBank
            };
            })
            : [];            

            console.log("allReport >>>", allReport)
            // Summary Row
            let highestTotalUser = 0, totalRegUser = 0, totalDirectRegisterUser = 0, totalInvUser = 0, totalActiveUser = 0, totalDepCnt = 0, totalUniqDep = 0 , totalDepAmt = 0, highestDepAmt = 0 , totalDepFirstCnt = 0 , totalDepFirstAmt = 0, totalDepFirstTimeAmt = 0, totalDepRegCnt = 0, totalDepRegCntSummary = 0;
            let totalDepRegAmt = 0, totalAdjustAmt = 0 , totalWithdrawCount = 0, totalUniqueWth = 0, totalWithdrawAmt = 0, highestWthAmt = 0, totalToResolve = 0, totalPlayerTurnover = 0, totalCommToWallet = 0;
            let totalCommToBank = 0, totalPromotionCost = 0, totalFortuneWheelAmt = 0, totalInviteAngpaoAmt = 0, totalPermanentAngpaoAmt = 0, totalMissionAmt = 0, totalPlayerRebate = 0, totalDownlineComm = 0;
            let totalDailyFreeCredit = 0, totalPushNotification = 0, totalLearnMore = 0, totalBindBankAcc = 0, totalWinLoss = 0, totalPlayerWithBank = 0 , totalPlayerWithoutBank = 0;
            let dailyTrxnSummary = [...allReport];

            dailyTrxnSummary.forEach(({ totalUser, registerUser, directRegisterUser, inviteUser, activeUser, depCount, uniqueDepSummary, depositAmt, maxDepositAmt, depositFirstCount, depositFirstAmount, depositFirstTimeAmt, depositRegularCount, depositRegularCountSummary, depositRegularAmount, adjustAmt, withdrawCount, uniqueWth,
                                        withdrawAmt, maxWthAmt, toResolve, playerTurnover, commToWallet, commToBank, promotionCost, fortuneWheelAmt, inviteAngpaoAmt, permanentAngpaoAmt, missionAmt, playerRebate, downlineComm, dailyFreeCredit, pushNotification, learnMore, bindBankAcc, winLoss, playerWithBank, playerWithoutBank }) => {
              if(highestTotalUser == 0) {
                highestTotalUser = totalUser;
              }
              else {
                if (totalUser > highestTotalUser) {
                  highestTotalUser = totalUser;
                }
              }
              totalRegUser        += registerUser;
              totalDirectRegisterUser += directRegisterUser;
              totalInvUser        += inviteUser;
              totalActiveUser     += activeUser
              totalDepCnt         += depCount;
              totalUniqDep        += uniqueDepSummary;
              totalDepAmt         += Number(FormatDataMobile(depositAmt));

              if(highestDepAmt == 0) {
                highestDepAmt = Number(FormatDataMobile(maxDepositAmt));
              }
              else {
                if (maxDepositAmt > highestDepAmt) {
                  highestDepAmt = Number(FormatDataMobile(maxDepositAmt));
                }
              }

              totalDepFirstCnt    += depositFirstCount;
              totalDepFirstAmt    += Number(FormatDataMobile(depositFirstAmount));
              totalDepFirstTimeAmt+= Number(FormatDataMobile(depositFirstTimeAmt));
              totalDepRegCnt      += depositRegularCount;
              totalDepRegCntSummary += depositRegularCountSummary;
              totalDepRegAmt      += Number(FormatDataMobile(depositRegularAmount));
              totalAdjustAmt      += Number(FormatDataMobile(adjustAmt));
              totalWithdrawCount  += withdrawCount;
              totalUniqueWth      += uniqueWth;
              totalWithdrawAmt    += Number(FormatDataMobile(withdrawAmt));

              if(highestWthAmt == 0) {
                highestWthAmt = Number(FormatDataMobile(maxWthAmt));
              }
              else {
                if (maxWthAmt < highestWthAmt) {
                  highestWthAmt = Number(FormatDataMobile(maxWthAmt));
                }
              }

              totalToResolve      += Number(FormatDataMobile(toResolve));

              if (String(playerTurnover) != 'N/A') {
                totalPlayerTurnover += Number(FormatDataMobile(playerTurnover));
              }

              totalCommToWallet   += Number(FormatDataMobile(commToWallet));
              totalCommToBank     += Number(FormatDataMobile(commToBank));
              totalPromotionCost  += Number(FormatDataMobile(promotionCost));
              totalFortuneWheelAmt+= Number(FormatDataMobile(fortuneWheelAmt));
              totalInviteAngpaoAmt+= Number(FormatDataMobile(inviteAngpaoAmt));
              totalPermanentAngpaoAmt += Number(FormatDataMobile(permanentAngpaoAmt));
              totalMissionAmt     += Number(FormatDataMobile(missionAmt));
              totalPlayerRebate   += Number(FormatDataMobile(playerRebate));
              totalDownlineComm   += Number(FormatDataMobile(downlineComm));
              totalDailyFreeCredit+= Number(FormatDataMobile(dailyFreeCredit));
              totalPushNotification   += Number(FormatDataMobile(pushNotification));
              totalLearnMore      += Number(FormatDataMobile(learnMore));
              totalBindBankAcc    += Number(FormatDataMobile(bindBankAcc));
              totalPlayerWithBank += playerWithBank;
              totalPlayerWithoutBank += playerWithoutBank;
            });

            let maxKey = parseInt(Math.max.apply(Math, dailyTrxnSummary.map(function(o) { return o.key; })))+1;

            dailyTrxnSummary= [
                ...dailyTrxnSummary,
                {
                  key: maxKey,
                  trxnDate: "Summary",
                  totalUser            : highestTotalUser,
                  registerUser         : totalRegUser,
                  directRegisterUser   : totalDirectRegisterUser,
                  inviteUser           : totalInvUser,
                  activeUser           : totalActiveUser,
                  depCount             : totalDepCnt,
                  uniqueDep            : totalUniqDep,
                  depositAmt           : totalDepAmt,
                  maxDepositAmt        : highestDepAmt,
                  depositFirstCount    : totalDepFirstCnt,
                  depositFirstAmount   : FormatDataMobile(totalDepFirstAmt),
                  depositFirstTimeAmt  : FormatDataMobile(totalDepFirstTimeAmt),
                  depositRegularCount  : totalDepRegCntSummary,
                  depositRegularAmount : totalDepRegAmt,
                  adjustAmt            : totalAdjustAmt,
                  withdrawCount        : totalWithdrawCount,
                  uniqueWth            : totalUniqueWth,
                  withdrawAmt          : totalWithdrawAmt,
                  maxWthAmt            : highestWthAmt,
                  toResolve            : FormatDataMobile(totalToResolve),
                  playerTurnover       : FormatDataMobile(totalPlayerTurnover), 
                  commToWallet         : FormatDataMobile(totalCommToWallet),
                  commToBank           : FormatDataMobile(totalCommToBank),
                  promotionCost        : FormatDataMobile(totalPromotionCost),
                  fortuneWheelAmt      : FormatDataMobile(totalFortuneWheelAmt),
                  inviteAngpaoAmt      : FormatDataMobile(totalInviteAngpaoAmt),
                  permanentAngpaoAmt   : FormatDataMobile(totalPermanentAngpaoAmt),
                  missionAmt           : FormatDataMobile(totalMissionAmt),
                  playerRebate         : FormatDataMobile(totalPlayerRebate),
                  downlineComm         : FormatDataMobile(totalDownlineComm),
                  dailyFreeCredit      : FormatDataMobile(totalDailyFreeCredit),
                  pushNotification     : FormatDataMobile(totalPushNotification),
                  learnMore            : FormatDataMobile(totalLearnMore),
                  bindBankAcc          : FormatDataMobile(totalBindBankAcc),
                  winLoss              : FormatDataMobile(totalWinLoss),
                  playerWithBank       : totalPlayerWithBank,
                  playerWithoutBank    : totalPlayerWithoutBank,
                },
              ];

          let todayDate = new Date().toLocaleDateString('en-GB').split('/').reverse().join('-');
          setTodayDate(todayDate);
          const filterDetail = allReport.filter(
            (data) => data.trxnDate //!== todayDate
          );

          const getdepositAmt = filterDetail.map((data) => data.depositAmt);
          var depositAmtTotal = getdepositAmt.reduce(function (previous, current) {
            return previous + current;
          }, 0);

          const getwithdrawAmt = filterDetail.map((data) => data.withdrawAmt);
          var confirmWithdrawAmtTotal = getwithdrawAmt.reduce(function (previous, current) {
            return previous + current;
          }, 0);

          const getToResolveAmt = filterDetail.map((data) => data.toResolve);
          var toResolveAmtTotal = getToResolveAmt.reduce(function (previous, current) {
            return previous + current;
          }, 0);

          const getCommToWalletAmt = filterDetail.map((data) => data.commToWallet);
          var commToWalletAmtTotal = getCommToWalletAmt.reduce(function (previous, current) {
            return previous + current;
          }, 0);

          const getCommToBankAmt = filterDetail.map((data) => data.commToBank);
          var commToBankAmtTotal = getCommToBankAmt.reduce(function (previous, current) {
            return previous + current;
          }, 0);

          setDepositAmtTotal(depositAmtTotal);
          if(depositCheckbox == true){
            setDepositChange(depositAmtTotal);
          }else{
            setDepositChange(0);
          }

          // setCommToWalletAmtTotal(-commToWalletAmtTotal);
          // if(comm2WalletCheckbox == true){
          //   setComm2WalletChange(-commToWalletAmtTotal);
          // }else{
          //   setComm2WalletChange(0);
          // }
          
          setWithAmountTotal(-confirmWithdrawAmtTotal);
          if(withdrawalCheckbox == true){
            setWithdrawalChange(-confirmWithdrawAmtTotal);
          }else{
            setWithdrawalChange(0);
          }

          setCommToBankAmtTotal(-commToBankAmtTotal);
          if(comm2BankCheckbox == true){
            setComm2BankChange(-commToBankAmtTotal);
          }else{
            setComm2BankChange(0);
          }
          
          setToResolveAmtTotal(toResolveAmtTotal);
          if(toResolveCheckbox == true){
            setToResolveChange(toResolveAmtTotal);
          }else{
            setToResolveChange(0);
          }

          setAllReport(dailyTrxnSummary);

          if (allReport.length == 0 ) {
            alertData.title = "Error";
            alertData.msg = "No data !";
            sharedErrorAlert(alertData);
            setTableLoading(false);
            return;
          }
        } 
        else {
          alertData.title = "Error";
          alertData.msg = "Connection not stable, please try again !";
          sharedErrorAlert(alertData);
        }        
      });

      
  };

  const columns = [
    {
        align: "center",
        title: "Date",
        dataIndex: "trxnDate",
        width: 100,
        ellipsis: true,
        fixed:"left",
        render:function(record) {
          return <p style={{color:"blue", marginTop: "10px"}}>{record}</p>
        }
    },
    {
        align: "center",
        title: "Start",
        dataIndex: "startTime",
        width: 100,
        ellipsis: true,
        style: [{"word-wrap":"break-word"}],
        render:function(record) {
          return <p style={{color:"blue", marginTop: "10px"}}>{record}</p>
        }
    },
    {
        align: "center",
        title: "End",
        dataIndex: "endTime",
        width: 100,
        ellipsis: true,
        style: [{"word-wrap":"break-word"}],
        render:function(record) {
          return <p style={{color:"blue", marginTop: "10px"}}>{record}</p>
        }
    },
    {
      align: "center",
      title: "Total User*",
      dataIndex: "totalUser",
      width: 100,
      //ellipsis: true,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return record != 'N/A' ? record : 'N/A';
      }
      
    },
    {
      align: "center",
      title: "Total Register User*",
      dataIndex: "registerUser",
      width: 100,
      headStyles:[{"font-size":"2px"}],
    
      // ellipsis: true,
    },
    {
      align: "center",
      title: "Direct Register User*",
      dataIndex: "directRegisterUser",
      width: 100,
      headStyles:[{"font-size":"2px"}],
    
      // ellipsis: true,
    },
    {
      align: "center",
      title: "Invited User*",
      dataIndex: "inviteUser",
      width: 100,
      style: [{"word-wrap":"break-word"}],
    },
    {
      align: "center",
      title: "Active User*",
      dataIndex: "activeUser",
      width: 100,
      style: [{"word-wrap":"break-word"}],
    },
    {
      align: "center",
      title: "Deposit#",
      dataIndex: "depCount",
      width: 100,
      style: [{"word-wrap":"break-word"}],
    },
    {
      align: "center",
      title: "Unique Deposit*",
      dataIndex: "uniqueDep",
      width: 100,
      style: [{"word-wrap":"break-word"}],
    },
    {
      align: "center",
      title: "Deposit Amount",
      dataIndex: "depositAmt",
      width: 150,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return FormatDataMobile(record) >= 100000?
              <p style={{color:"red", marginTop: "10px"}}>{FormatDataMobile(record)}</p>
              : FormatDataMobile(record);
      }
    },
    {
      align: "center",
      title: "Max Deposit",
      dataIndex: "maxDepositAmt",
      width: 100,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return record != 'N/A' ? FormatDataMobile(record) : 'N/A';
      }
    },
    {
        align: "center",
        title: "New Unique Deposit#",
        dataIndex: "depositFirstCount",
        width: 100,
        style: [{"word-wrap":"break-word"}],
        render:function(record) {
          return <p style={{color:"blue", marginTop: "10px"}}>{record}</p>
        }
    },
    {
      align: "center",
      title: "New Unique Deposit Amount",
      dataIndex: "depositFirstAmount",
      width: 100,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return <p style={{color:"blue", marginTop: "10px"}}>{FormatDataMobile(record)}</p>
      }
    },
    {
      align: "center",
      title: "First New Unique Deposit Amount",
      dataIndex: "depositFirstTimeAmt",
      width: 100,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return record2.trxnDate == todayDate ?
              <p style={{color:"blue", marginTop: "10px"}}>N/A</p>
              : <p style={{color:"blue", marginTop: "10px"}}>{FormatDataMobile(record)}</p>;
      }
    },
    {
      align: "center",
      title: "Regular Deposit#",
      dataIndex: "depositRegularCount",
      width: 100,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return <p style={{color:"blue", marginTop: "10px"}}>{record}</p>
      }
    },
    {
      align: "center",
      title: "Regular Deposit Amount",
      dataIndex: "depositRegularAmount",
      width: 150,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return <p style={{color:"blue", marginTop: "10px"}}>{FormatDataMobile(record)}</p>
      }
    },
    {
      align: "center",
      title: "Total Adjustment",
      dataIndex: "adjustAmt",
      width: 150,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return FormatDataMobile(record);
      }
    },
    {
      align: "center",
      title: "Withdrawals#",
      dataIndex: "withdrawCount",
      width: 110,
      style: [{"word-wrap":"break-word"}],
    },
    {
      align: "center",
      title: "Unique Withdrawals#",
      dataIndex: "uniqueWth",
      width: 110,
      style: [{"word-wrap":"break-word"}],
    },
    {
      align: "center",
      title: "Withdraw Amount",
      dataIndex: "withdrawAmt",
      width: 150,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return FormatDataMobile(record) <= -100000?
              <p style={{color:"red", marginTop: "10px"}}>{FormatDataMobile(record)}</p>
              : FormatDataMobile(record);
      }
    },
    {
      align: "center",
      title: "Max Withdraw",
      dataIndex: "maxWthAmt",
      width: 150,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return record != 'N/A' ? FormatDataMobile(record) : 'N/A';
      }
    },
    {
      align: "center",
      title: "To Resolve",
      dataIndex: "toResolve",
      width: 100,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return FormatDataMobile(record);
      }
    },
    {
      align: "center",
      title: "Turnover",
      dataIndex: "playerTurnover",
      width: 150,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return record2.trxnDate == todayDate && record == 'N/A'?
              <p style={{fontWeight: "bold", marginTop: "10px"}}>N/A</p>
              : FormatDataMobile(record);
      }
    },
    {
      align: "center",
      title: "Comm2Wallet",
      dataIndex: "commToWallet",
      width: 150,
      style: [{"word-wrap":"break-word"}],
      render:function(record) {
        return FormatDataMobile(record);
      }
    },
    {
      align: "center",
      title: "Comm2Bank",
      dataIndex: "commToBank",
      width: 150,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return FormatDataMobile(record);
      } 
    },
    {
      align: "center",
      title: "Provider Win / Loss",
      dataIndex: "winLoss",
      width: 150,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return FormatDataMobile(record);
      } 
    },
    {
      align: "center",
      title: "Player With Bank",
      dataIndex: "playerWithBank",
      width: 150,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return FormatDataMobile(record);
      } 
    },
    {
      align: "center",
      title: "Player Without Bank",
      dataIndex: "playerWithoutBank",
      width: 150,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return FormatDataMobile(record);
      } 
    },
    {
      align: "center",
      title: "Promotion Cost",
      dataIndex: "promotionCost",
      width: 120,
      hidden: true,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return <p style={{marginTop: "10px", color: "#ab3da9"}}><b>{FormatDataMobile(record)}</b></p>
      }
    },
    {
      align: "center",
      title: "Invite Angpao Amount",
      dataIndex: "inviteAngpaoAmt",
      width: expand == true ? 160 : 0,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return <p style={{marginTop: "10px", color: "#de25da"}}>{FormatDataMobile(record)}</p>
      },
      ellipsis: true,
    },
    {
      align: "center",
      title: "Fortune Wheel Amount",
      dataIndex: "fortuneWheelAmt", 
      width: expand == true ? 160 : 0,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return <p style={{marginTop: "10px", color: "#de25da"}}>{FormatDataMobile(record)}</p>
      },
      ellipsis: true,
    },
    {
      align: "center",
      title: "Permanent Angpao Amount",
      dataIndex: "permanentAngpaoAmt",
      width: expand == true ? 180 : 0,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return <p style={{marginTop: "10px", color: "#de25da"}}>{FormatDataMobile(record)}</p>
      },
      ellipsis: true,
    },
    {
      align: "center",
      title: "Mission Amount",
      dataIndex: "missionAmt",
      width: expand == true ? 130 : 0,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return <p style={{marginTop: "10px", color: "#de25da"}}>{FormatDataMobile(record)}</p>
      },
      ellipsis: true,
    },
    {
      align: "center",
      title: "Daily Free Credit",
      dataIndex: "dailyFreeCredit",
      width: expand == true ? 130 : 0,
      hidden: true,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return <p style={{marginTop: "10px", color: "#de25da"}}>{FormatDataMobile(record)}</p>
      },
      ellipsis: true,
    },
    {
      align: "center",
      title: "Push Notification",
      dataIndex: "pushNotification",
      width: expand == true ? 130 : 0,
      hidden: true,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return <p style={{marginTop: "10px", color: "#de25da"}}>{FormatDataMobile(record)}</p>
      },
      ellipsis: true,
    },
    {
      align: "center",
      title: "Learn More",
      dataIndex: "learnMore",
      width: expand == true ? 120 : 0,
      hidden: true,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return <p style={{marginTop: "10px", color: "#de25da"}}>{FormatDataMobile(record)}</p>
      },
      ellipsis: true,
    },
    {
      align: "center",
      title: "Bind Bank Account",
      dataIndex: "bindBankAcc",
      width: expand == true ? 140 : 0,
      hidden: true,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return <p style={{marginTop: "10px", color: "#de25da"}}>{FormatDataMobile(record)}</p>
      },
      ellipsis: true,
    },
    {
      align: "center",
      title: "Player Rebate Amount",
      dataIndex: "playerRebate",
      width: expand == true ? 160 : 0,
      enableHiding: true,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return <p style={{marginTop: "10px", color: "#de25da"}}>{FormatDataMobile(record)}</p>
      },
      ellipsis: true,
    },
    {
      align: "center",
      title: "Downline Commission",
      dataIndex: "downlineComm",
      width: expand == true ? 160 : 0,
      enableHiding: true,
      style: [{"word-wrap":"break-word"}],
      render:function(record, record2) {
        return <p style={{marginTop: "10px", color: "#de25da"}}>{FormatDataMobile(record)}</p>
      },
      ellipsis: true,
    },
  ];

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    if (allReport.length === 0 ) {
      alertData.title = "Error";
      alertData.msg = "No data to download !";
      sharedErrorAlert(alertData);
      return;
    }

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(10);
    let curDate = new Date();

    const title = `Daily Transaction Stats Report (${moment(curDate).format('YYYY-MM-DD')})\n\n\n`;

    const headers = [
      [
        columns[0].title,
        columns[1].title,
        columns[2].title,
        columns[3].title,
        columns[4].title,
        columns[5].title,
        columns[6].title,
        columns[7].title,
        columns[8].title,
        columns[9].title,
        columns[10].title,
        columns[11].title,
        columns[12].title,
        columns[13].title,
        columns[14].title,
        columns[15].title,
        columns[16].title,
        columns[17].title,
        columns[18].title,
        columns[19].title,
        columns[20].title,
        columns[21].title,
        columns[22].title,
        columns[23].title,
        columns[24].title,
      ],
    ]; 

    var filteredReport = [...allReport];
    const data = filteredReport.map((data) => {
      return [
        data[columns[0].dataIndex],                   //Date
        data[columns[1].dataIndex],                   //start
        data[columns[2].dataIndex],                   //end
        data[columns[3].dataIndex],                   //total user
        data[columns[4].dataIndex],                   //register
        data[columns[5].dataIndex],                   //invite
        data[columns[6].dataIndex],                   //active
        data[columns[7].dataIndex],                   //Deposit
        data[columns[8].dataIndex],                   //unique deposit
        FormatDataMobile(data[columns[9].dataIndex]), //deposit amount
        FormatDataMobile(data[columns[10].dataIndex]),//max deposit
        data[columns[11].dataIndex],                  //new unique deposit
        FormatDataMobile(data[columns[12].dataIndex]),//new unique deposit amount
        data[columns[13].dataIndex],                  //regular deposit
        FormatDataMobile(data[columns[14].dataIndex]),//regular deposit amount
        FormatDataMobile(data[columns[15].dataIndex]),//total adjustment
        data[columns[16].dataIndex],                  //withdraw
        data[columns[17].dataIndex],                  //unique withdraw
        FormatDataMobile(data[columns[18].dataIndex]),//withdraw amount
        FormatDataMobile(data[columns[19].dataIndex]),//max withdraw
        FormatDataMobile(data[columns[20].dataIndex]),//to resolve
        FormatDataMobile(data[columns[21].dataIndex]),//turnover
        FormatDataMobile(data[columns[22].dataIndex]),//comm2wallet
        FormatDataMobile(data[columns[23].dataIndex]),//comm2bank
        data[columns[24].dataIndex],                  //remark

      ];
    });

    let content = {
      startY: 80,
      head: headers,
      body: data,
      headStyles: { fillColor: "#1DA57A"},
      styles:{fontSize:7}
    };


    doc.text(title, marginLeft, 40);

    doc.autoTable(content);   
    doc.save(`Daily Transaction Stats Report.pdf`);
  };

  const routes = [
    {
      path: "/",
      breadcrumbName: "Daily Transaction Stats",
    },
    {
      //   path: "first",
      breadcrumbName: "Report",
    },
    {
      path: "commBalanceReport",
      breadcrumbName: "Daily Transaction Stats Report",
    },
  ];

  const itemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 2;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={paths.join("/")}>{route.breadcrumbName}</Link>
    );
  };

  const styles = {
    tableContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    expandStyle: {
      marginLeft: 20,
    },
    inlineBlock: {
      display: "inline-block",
    },
    paginationStyle: {
      marginTop: 50,
    },
    pageHeaderContainer: {
      justifyContent: isMobile ? "" : "space-between",
      // display: "flex",
      flexDirection: isMobile ? "column" : "",
      alignItems: isMobile ? "center" : "",
      display: "inline",
    },
    resetBtn: {
      marginLeft: 10,
    },
    inline: {
      display: "inline",
    },
    hide: {
      marginLeft: 10000,
    },
    datePickerStyle: {
      width: 280,
    },
    datePickerSelectionStyle: {
      marginLeft: 10,
      width: 120,
    },
    refreshBtn: {
      marginLeft: 10,
    },
    mobilePageHearderContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    eachButtonContainer: {
      alignItems: "center",
      display: "flex",
      height: 50,
      marginLeft: 15,
      marginRight: 15,
      justifyContent: "center",
    },
    mobileSearchResetBtn: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      height: 50,
      alignItems: "center",
      paddingLeft: 60,
      paddingRight: 60,
    },
    btnContainer: {
      width: "100vw",
      padding: 10,
    },
    pdfStyle: {
      borderRadius: 10,
      width: 1200,
    },
    decStyle: {
      display: "flex",
      flexDirection: "row",
    },
    decLable: {
      width: 200,
    },
    decContainer: {
      paddingLeft: 75,
    },
    checkbox: {
      marginTop: -25,
      paddingBottom: 30,
      paddingLeft: 10,
    },
    webRow: {
      backgroundColor: "#FFFFFF",
      paddingTop: 50,
      paddingLeft: 30,
      paddingBottom: 50,
      fixed: "left",
    },
    mobileRow: {
      backgroundColor: "#FFFFFF",
      paddingLeft: 30,
    },
    cardStyle: {
      borderRadius: 10,
      height: 160,
      width: 200,
      border: "none",
      marginTop: -70,
      marginLeft: -30,
      marginRight: 40,
      marginBottom: -90,
      fixed: "left",
      display: "inline-block"
    },
    cardStyle2: {
      borderRadius: 0,
      height: 160,
      width: 1050,
      border: "none",
      marginTop: -70,
      marginLeft: -30,
      marginRight: 15,
      marginBottom: -90,
      fixed: "left"
    },
    cardStyle3: {
      borderRadius: 0,
      height: 160,
      width: 150,
      border: "none",
      marginTop: -70,
      marginLeft: -5,
      marginRight: 0,
      marginBottom: -90,
      fixed: "left"
    },
    statistic1: {
      width: 130,
    },
    statisticPercentage: {
      width: 100,
    },
    statistic2: {
      width: 150,
    },
    statistic3: {
      width: 150,
    },
    statistic4: {
      width: 120,
    },
    statistic5: {
      width: 130,
    },
    statistic6: {
      width: 150,
    },
    equal: {
      width: 25,
    },
    openBracket: {
      width: 15,
    },
    closeBracket: {
      width: 15,
    },
    plus: {
      width: 28,
    },
    minus1: {
      width: 20,
    },
    minus2: {
      width: 24,
    },
    expandButton: {
      marginLeft: 30,
    },
    headStyles: {
      backgroundColor: "#e2f2f0",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      fontSize: 20,
      height: 20,
    },
    headStyles2: {
      backgroundColor: "#e2f2f0",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      fontSize: 20,
      height: 20,
    },
    bodyStyle: {
      backgroundColor: "#e2f2f0",
      paddingLeft: 30,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      justifyContent: "flex-start",
      display: "flex",
      height: 60,
    },
    bodyStyle2: {
      backgroundColor: "#e2f2f0",
      paddingLeft: 30,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      justifyContent: "flex-start",
      display: "flex",
      height: 60,
    },
  };

  return (
    <div>
      {
        DailyTrxnStatsVw(role,username,compCode)?
        
        <div className="eachPageContent">
          <div>
            <PageHeader
              ghost={false}
              // title="Daily Transaction Stats Report"
            >
              <div style={styles.pageHeaderContainer}>
                {/* <Button
                  style={styles.refreshBtn}
                  type="primary"
                  onClick={() => {
                    // reportList();
                    window.location.reload();
                  }}
                >
                  Refresh Page
                </Button> */}
              
            
                <DatePicker
                    key="1"
                    style={{...styles.datePickerStyle, ...styles.refreshBtn}}
                    format="YYYY-MM-DD"
                    onChange={trxDatePicker}
                    value={trxDateFrom}
                    ref={reportDateFrom}
                    refs
                    placeholder={"Select Date From"}
                    disabledDate={(current)=> {
                      return current > moment();//.subtract(1, 'day');
                    }}
                />
                <DatePicker
                  key="2"
                  style={{...styles.datePickerStyle, ...styles.refreshBtn}}
                  format="YYYY-MM-DD"
                  onChange={trxDatePicker2}
                  value={trxDateTo}
                  ref={reportDateTo}
                  placeholder={"Select Date To"}
                  disabledDate={(current)=> {
                    let dateFrom = trxDateFrom;
                    if (dateFrom) {
                      return current < dateFrom || current > moment();//.subtract(1, 'day');
                    }else {
                      return  current > moment();//.subtract(1, 'day');
                    }
                  }}
                />
                <div style={styles.inlineBlock}>
                  <Space>
                    <Select style={{...styles.datePickerSelectionStyle}} value={type} type={type} onChange={setType}>
                      <Option value="custom">Custom</Option>
                      <Option value="today">Today</Option>
                      <Option value="yesterday">Yesterday</Option>
                      <Option value="thisweek">This Week</Option>
                      <Option value="thismonth">This Month</Option>
                      <Option value="lastmonth">Last Month</Option>
                    </Select>
                  </Space>
                  <Button
                    type={"primary"}
                    icon={<SearchOutlined
                    onClick={searchBar}
                    />}
                  />
                  <Button style={{...styles.resetBtn,...styles.refreshBtn}} onClick={resetBtn}>
                    Reset
                  </Button>
                </div>
                <Button 
                      type={"primary"} 
                      style={styles.refreshBtn} 
                      icon={<FileExcelOutlined />}
                      onClick={() => {
                          // if (expandButton == false){
                          //   detailsBtn();
                          // }
                          onClickExportBtn();
                          
                      }}
                      
                  >
                    Export to Excel
                </Button>
                <span style={styles.expandStyle}>Expandable:  <Switch  checkedChildren="ON" unCheckedChildren="OFF" onClick={(event) => { expandButton() }}/></span>
              </div>
            </PageHeader>
            <div style={styles.tableContainer}>
              <div style={styles.checkbox}>
                <p>
                <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
                  {/* <span style={styles.expandButton}>Expandable:  <Switch color="black" checkedChildren="ON" unCheckedChildren="OFF" onChange={()=>detailsBtn()}/></span> */}
                </p>
              </div>
              <Row style={styles.webRow}>
                <Card
                  title="Win / Lose &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (%) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; = &nbsp; Deposit &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - &nbsp;[ Withdrawal &nbsp;&nbsp;&nbsp; (&nbsp;Comm2Bank &nbsp;&nbsp; ) + &nbsp; To Resolve&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;] " 
                  style={styles.cardStyle2}
                  headStyle={styles.headStyles2}
                  bodyStyle={styles.bodyStyle2}
                >
                  <Statistic  
                    style={styles.statistic1}
                    value={
                      ((depositChange) - (withdrawalChange + toResolveChange)).toFixed(2)
                  }
                    valueStyle={{ fontSize: 17 }}
                  />
                  <Statistic
                    style={styles.openBracket}
                    value={"("}
                    valueStyle={{ fontSize: 17 }}
                  />
                  <Statistic
                    style={styles.statisticPercentage}
                    value={
                    Number((1 - ((withdrawalChange + toResolveChange)/(depositChange))) * 100).toFixed(2) > 0 || 
                    Number((1 - ((withdrawalChange + toResolveChange)/(depositChange))) * 100).toFixed(2) < 0 ? 
                    Number((1 - ((withdrawalChange + toResolveChange)/(depositChange != 0 ? depositChange : 0))) * 100).toFixed(2)+'%' : 
                    Number(0).toFixed(2)+'%'
                  }
                    valueStyle={{ fontSize: 17 }}
                  />
                  <Statistic
                    style={styles.closeBracket}
                    value={")"}
                    valueStyle={{ fontSize: 17 }}
                  />
                  <Statistic
                    style={styles.equal}
                    value={"="}
                    valueStyle={{ fontSize: 17 }}
                  />
                  <Statistic
                    style={styles.statistic2}
                    value={Number(depositChange).toFixed(2)
                  }
                    valueStyle={{ fontSize: 17 }}
                  />
                  <Statistic
                    style={styles.minus1}
                    value={"-"}
                    valueStyle={{ fontSize: 17 }}
                  />
                  <Statistic
                    style={styles.openBracket}
                    value={"["}
                    valueStyle={{ fontSize: 17 }}
                  />
                  <Statistic
                    style={styles.statistic4}
                    value={Number(withdrawalChange).toFixed(2)
                  }
                    valueStyle={{ fontSize: 17 }}
                  />
                  {/* <Statistic
                    style={styles.plus}
                    value={"+"}
                    valueStyle={{ fontSize: 17 }}
                  /> */}
                  <Statistic
                    style={styles.openBracket}
                    value={"("}
                    valueStyle={{ fontSize: 17 }}
                  />
                  <Statistic
                    style={styles.statistic5}
                    value={Number(comm2BankChange).toFixed(2)
                  }
                    valueStyle={{ fontSize: 17 }}
                  />
                  <Statistic
                    style={styles.closeBracket}
                    value={")"}
                    valueStyle={{ fontSize: 17 }}
                  />
                  <Statistic
                    style={styles.plus}
                    value={"+"}
                    valueStyle={{ fontSize: 17 }}
                  />
                  <Statistic
                    style={styles.statistic6}
                    value={Number(toResolveChange).toFixed(2)
                  }
                    valueStyle={{ fontSize: 17 }}
                  />
                  <Statistic
                    style={styles.closeBracket}
                    value={"]"}
                    valueStyle={{ fontSize: 17 }}
                  />
                </Card>
              </Row>
              <Table
                options={{
                  // Allow user to hide/show
                  // columns from Columns Button
                  columnsButton: true,
                }}
                loading={tableLoading} 
                columns={columns}
                dataSource={allReport}
                className="cs_dailyTrxnStat"
                scroll={{ x: 100, y: null }}
                exportable={true}
                // initialState={{ columnVisibility: { fortuneWheelAmt: false } }}
                pagination={{
                  defaultPageSize: isMobile ? 10 : 100,
                  showSizeChanger: true,
                  pageSizeOptions: isMobile ? ["5", "10", "20"] : settings.pageSizeOptions,
                  style: styles.paginationStyle,
                }}
                exportableProps={{
                  // feedFields,
                  fileName: "DailyTransactionStatsReport_"+todayDate,
                  btnProps: {
                    type: "primary",
                    icon: <FileExcelOutlined />,
                    id:"export-button-1",
                    children: <span>Export to Excel</span>,
                    style: {display:"none"}
                  },
                }}
              />
              <br></br>
              <p><b>REMARK :</b></p>
              <p>(1) Win / Lose = (1 - (Total Withdrawal / Total Deposit)) x 100</p>
              <p>(2) Percentage(%) may be infinity if Deposit are not selected</p>
              <p>(3) Promotion Cost is expandable</p>
              <p>(4) <span style={{color:"red"}}>Turnover</span> columns on today <b>{todayDate}</b> was disabled due to heavy loading.</p>
              <p>(5) Withdrawal already included Comm2Bank</p>
              <p>(6) (Amount in <span style={{color:"red"}}>RED</span> color is &#8250;= <span style={{color:"red"}}>100000</span> OR &#8249;= <span style={{color:"red"}}>-100000</span> (THB) )</p>
              <p>(7) Transaction listed in maximum 31 days.</p>
              <p>(8) '#' mean 'Count of Times'</p>
              <p>(9) REGISTER USER is the Daily New Registered User, INVITE USER is also part of REGISTER USER, they are introduced by Existing Players (Not Agent). --&#8250; so REGISTER USER deduct INVITE USER = Agent Direct Invite User.</p>
              <p>(10) ACTIVE USER is the Daily Login User (within the day), regardless of playing or not playing games.</p>
              <p>(11) DEPOSIT # is the Total Daily Deposit, including 1 player with multiple deposit.</p>
              <p>(12) UNIQUE DEPOSIT = number of unique user who deposited. Eg: If player with multiple deposit, UNIQUE DEP consider ONE.</p>
              <p>(13) MAX DEPOSIT and MAX WITHDRAW = the largest deposit and withdrawal amount in 1 Day.</p>
              <p>(14) UNIQUE DEPOSIT = NEW UNIQUE DEPOSIT # + REGULAR DEPOSIT #.</p>
              <p>(15) DEPOSIT # = NEW UNIQUE DEPOSIT AMOUNT + REGULAR DEPOSIT AMOUNT.</p>   

              <p style={{color:"blue"}}>Eg-1: 2021-06-27: there is total 3463 Active Users (Login User), 310 is New Register User, which 246 is invited by Players, 64 (= 310 - 246) is invited by Agent</p>
              <p style={{color:"blue"}}>Eg-2: 2021-06-27: Total Deposit = 163509.54 (THB), done by 520 Unique players from 1383 deposit, Max Deposit is 2000.21. From 520 Unique Deposit, New Unique Deposit is 7733.41 by 52 players, Regular Deposit is 155776.13 by 468 players</p>
              <div style={{...styles.inline, ...styles.hide}}>
                <PickerWithType type={type} onChange={(value) => console.log(value)} />
              </div>
            </div>
          </div>
        </div>:
        <div style={{textAlign:"center",color:"red",fontSize:"150%"}}><h1 style={{color:"red"}}>Not Allowed to Access This Page</h1></div>
      }
    </div>
      
  );
}